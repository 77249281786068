import {
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Progress,
  Spacer,
  Text,
} from '@chakra-ui/react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import React, { useMemo, useContext } from 'react';
import CQContainer from '../../components/layout/CQContainer';
import { FONT_FAMILY } from '../../constants/Theme';
import { useDropzone } from 'react-dropzone';
import { acceptStyle, baseStyle, focusedStyle, rejectStyle } from './styles';
import {
  CrossIcon,
  PinIcon,
  RedCrossIcon,
  TickIcon,
  UploadIcon,
} from '../../constants/IconData';
import AppColors from '../../constants/AppColors';
import { withProvider } from '../../hoc/withProvider';
import UploadProvider, { UploadContext } from '../../providers/UploadProvider';
import { AuthContext } from '../../providers/AuthProvider';
import useDeviceScreen from '../../hooks/useDeviceScreen';
import { WarningIcon } from '@chakra-ui/icons';

const UploadContainer = props => {
  const {
    isSubmitting,
    uploadedCharts,
    setUploadedCharts,
    uploadCharts,
    deleteChart,
    submitUploadedCharts,
  } = useContext(UploadContext);

  const authContext = useContext(AuthContext);

  const canUpload = authContext.currentUser?.can_upload;

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      accept: {
        'text/doc': ['.doc', '.docx', '.pdf', '.xls', '.xlsx', '.hl7'],
      },
      useFsAccessApi: false,
      onDrop: async acceptedFiles => {
        await uploadCharts(acceptedFiles, 0);
      },
      disabled: !canUpload,
    });

  const submitCharts = async () => {
    await submitUploadedCharts();
  };
  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  const handleRemoveFile = async id => {
    await deleteChart(id);
  };

  const { isWeb } = useDeviceScreen();

  const handleRemoveState = index => {
    const data = uploadedCharts.filter((chart, i) => i !== index);
    setUploadedCharts([...data]);
  };

  // Function to check if any uploaded file name exceeds 200 characters
  const validateFileNameLength = () => {
    return uploadedCharts.some((chart) => chart.name.length > 200);
  };

  return (
    <>
      <CQContainer>
        <Box>
          <Center>
            <Text
              fontSize={'30px'}
              fontFamily={FONT_FAMILY.baiJamurjee}
              fontWeight={500}
            >
              Upload Chart
            </Text>
          </Center>
          <Center>
            <Flex
              borderRadius={10}
              boxShadow="0 0 10px 0 rgba(0,0,0,0.05)"
              minHeight="100px"
              width={isWeb ? '60%' : '100%'}
              mt={10}
              bgColor={'white'}
              flexDirection={'column'}
            >
              {canUpload ? (
                <>
                  <Flex
                    m={8}
                    className="container"
                    style={{ cursor: 'pointer' }}
                    flexDirection={'column'}
                  >
                    <Flex flexDirection={'column'} {...getRootProps({ style })}>
                      <input {...getInputProps()} />
                      <UploadIcon />
                      <HStack
                        flexWrap={'wrap'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        marginTop="10px"
                        marginBottom="10px"
                      >
                        <PinIcon />
                        <Text
                          color={'black'}
                          fontSize={['20px', '20px', '24px', '30px']}
                        >
                          Add file or drop files here
                        </Text>
                      </HStack>
                      <HStack
                        width={'100%'}
                        flexWrap={'wrap'}
                        justifyContent={'center'}
                        alignItems={'center'}
                      >
                        <Text
                          color={'black'}
                          fontSize={['20px', '20px', '24px', '30px']}
                          fontWeight="normal"
                        >
                          Format :
                        </Text>
                        <Text
                          color={'black'}
                          fontSize={['20px', '20px', '24px', '30px']}
                          fontWeight="bold"
                        >
                          XLS, XLSX, PDF, DOC, DOCX, HL7
                        </Text>
                      </HStack>
                    </Flex>
                  </Flex>
                  {uploadedCharts?.length ? (
                    <PerfectScrollbar
                      style={{
                        maxHeight: '200px',
                        margin: 35,
                        marginTop: 0,
                        paddingRight: 10,
                        paddingBottom: 10,
                      }}
                    >
                      {uploadedCharts.map((chart, index) => (
                        <Box key={index} mt={4} mr={2}>
                          <HStack mb={2}>
                            <Text
                              fontFamily={FONT_FAMILY.ptSans}
                              style={{
                                overflowX: 'auto',
                                whiteSpace: 'nowrap',
                                maxWidth: '100%',
                                scrollbarWidth: 'thin',
                              }}
                            >
                              {chart.name}
                            </Text>
                            <Spacer />
                            <Text fontFamily={FONT_FAMILY.ptSans}>
                              {chart.size}
                            </Text>
                            {chart.uploaded ? (
                              <CrossIcon
                                onClick={() => handleRemoveFile(chart.id)}
                                style={{ cursor: 'pointer' }}
                              />
                            ) : null}
                            {chart.uploaded ? (
                              chart.name.length > 200 ? (
                                <WarningIcon color={AppColors.secondary} boxSize={5} />
                              ) : (
                                <TickIcon style={{ width: 22, height: 22 }} />
                              )
                            ) : null}
                            {chart.cancelled ? (
                              <RedCrossIcon
                                style={{ cursor: 'pointer' }}
                                onClick={() => handleRemoveState(index)}
                              />
                            ) : null}
                          </HStack>
                          <Progress
                            value={chart.value}
                            size="xs"
                            colorScheme={chart.cancelled ? 'red' : 'teal'}
                            borderRadius={10}
                          />
                        </Box>
                      ))}
                    </PerfectScrollbar>
                  ) : null}
                  {uploadedCharts?.length ? (
                    <Center mb={8}>
                      <Button
                        isLoading={isSubmitting}
                        onClick={() => submitCharts()}
                        variant="outline"
                        borderRadius={20}
                        borderWidth={2}
                        px={10}
                        _hover={{
                          transition: 'all .1s ease',
                          bgColor: AppColors.white,
                          color: AppColors.secondary,
                          outline: `2px solid ${AppColors.secondary}`,
                        }}
                        borderColor={AppColors.secondary}
                        disabled={
                          !(
                            uploadedCharts.every(chart => chart.id) &&
                            uploadedCharts.some(chart => chart.uploaded) &&
                            !validateFileNameLength()
                          )
                        }
                      >
                        Submit
                      </Button>
                    </Center>
                  ) : null}
                  {validateFileNameLength() && (
                    <Center mb={4}>
                      <Text color="red" fontSize="md" textAlign="center" mb={4} mt={-4}>
                        * File name exceeds 200-character limit.
                      </Text>
                    </Center>
                  )}

                </>
              ) : (
                <Center m={8}>
                  <Text color="red.500" fontSize="lg" textAlign="center">
                    You do not have permission to upload charts.
                  </Text>
                </Center>
              )}
            </Flex>
          </Center>
        </Box>
        {canUpload && (
          <HStack display={'flex'} justifyContent={'center'} mt={2}>
            <Text fontSize={'14px'} fontFamily={FONT_FAMILY.ptSans}>
              By uploading files, you agree to our{' '}
              <span
                style={{
                  fontSize: '14px',
                  fontFamily: FONT_FAMILY.ptSans,
                  fontWeight: 'bold',
                }}
              >
                Terms of Service
              </span>
            </Text>
          </HStack>
        )}
      </CQContainer>
    </>
  );
};

export default withProvider(UploadProvider, UploadContainer);
