/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  CircularProgress,
  CircularProgressLabel,
  Grid,
  GridItem,
  HStack,
  Skeleton,
  Switch,
  Text,
  Tooltip,
  Icon,
  Button,
} from '@chakra-ui/react';
import { InfoOutlineIcon } from "@chakra-ui/icons";
import React, { useContext, useEffect, useState } from 'react';
import CQContainer from '../../components/layout/CQContainer';
import {
  Heading,
  PrimaryButton,
  useBoolean,
} from '@laxmimanogna/code-quick-components';
import { FONT_FAMILY } from '../../constants/Theme';
import AppColors from '../../constants/AppColors';
import CQLineChart from '../../components/CQLineChart';
import { TriangleIcon, TriangleRedIcon } from '../../constants/IconData';
import RevenueTile from '../../components/RevenueTile';
import { priceConverter } from '../../utils/priceConverter';
import DashboardProvider, {
  DashboardContext,
} from '../../providers/DashboardProvider';
import ProvidersTable from './components/ProvidersTable';
import { withProvider } from '../../hoc/withProvider';
import UploadTable from './components/UploadTable';
import HospitalsTable from './components/HospitalTable';
import { getDateInterval } from './getDateInterval';
import { add, format } from 'date-fns';
import { XAXISLABEL } from '../../constants/constants';
import TilePopup from './components/TilePopup';
import { getChartData } from './getChartData';
import { AuthContext } from '../../providers/AuthProvider';
import {
  getCurrentUserTypeId,
  getCurrentUserTypeName,
} from '../../utils/getCurrentUserTypeId';
import DepartmentTable from './components/DepartmentTable';
import PhysicianTable from './components/PhysicianTable';
import RVUOpportunity from './components/RVUOpportunity';
import AIRiskMonitor from './components/AIRiskMonitor';
import {
  convertToPositiveDollar,
  replaceRoute,
} from '../../utils/common.utils';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useRef } from 'react';
import CustomDateFilter from '../../components/dashboard_components/DashboardFilter';
import CustomIcon from '../audit_sheet/components/CustomIcon';
import ROUTES from '../../constants/Routes';
import { utcToZonedTime } from 'date-fns-tz';
import RevenueBreakdownTile from '../../components/RevenueBreakdownTile';
import CollectedRevenueTile from '../../components/CollectedRevenueTile';
import PotentialRevenueTile from '../../components/PotentialRevenueTile';

const DashboardContainer = props => {
  const authContext = useContext(AuthContext);
  const dashboardContext = useContext(DashboardContext);
  const {
    filterParams,
    getRVUOppDropdown,
    dropdown,
    setRVUOppFilter,
    showComparison,
    setShowComparison,
    providers,
  } = dashboardContext;
  const [chartActivity, setChartActivity] = useState({});
  const [revenueOpp, setRevenueOpp] = useState({});
  const [isOpenDateFilter, iodfState] = useBoolean(false);
  const [isLoading] = useBoolean(false);
  const [isTileModalOpen, itmState] = useBoolean(false);
  const [currentTile, setCurrentTile] = useState(null);
  const [currentUserType, setCurrentUserType] = useState({});
  const [name, setName] = useState('');
  const [practiceChartData, setPracticeChartData] = useState({});
  const [stateChartData, setStateChartData] = useState({});
  const [auditChartData, setAuditChartData] = useState({});
  const [showOutstanding, setShowOutstanding] = useState(true);
  const [checked, setChecked] = useState('');
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);
  const { currentUser } = authContext;
  const [queryParameters] = useSearchParams('search');
  const table = queryParameters.get('table');
  const navigate = useNavigate();
  const isUserProvider = currentUser?.user_type === 'PROVIDER';

  const toggleComponent = () => {
    setShowOutstanding(prevState => !prevState);
  };

  const [showCollectedRevenue, setShowCollectedRevenue] = useState(false);

  const toggleRevenueBreakdown = () => {
    setShowCollectedRevenue(!showCollectedRevenue);
  };

  const yesterday = add(new Date(), {
    days: -1,
  });
  const providerRef = useRef();

  const initialSelectedFilter = localStorage.getItem('selectedFilter')
    ? localStorage.getItem('selectedFilter')
    : JSON.stringify('month');

  useEffect(() => {
    localStorage.setItem('selectedFilter', initialSelectedFilter);
    if (table) {
      window.scrollTo(0, providerRef.current.offsetTop);
    }
  }, []);

  const selectedFilter = JSON.parse(localStorage.getItem('selectedFilter'));

  useEffect(() => {
    const data = getCurrentUserTypeName(authContext.currentUser);
    setName(data);
    const obj = getCurrentUserTypeId(authContext.currentUser);
    setCurrentUserType(obj);
    let initialFilterParams = {};
    if (selectedFilter) {
      if (selectedFilter.toLowerCase() === 'custom') {
        const selectedCustomDates = JSON.parse(
          window.localStorage.getItem('customDateFilter')
        );
        const haveSelectedCustomDates =
          selectedCustomDates && Object.keys(selectedCustomDates)?.length;
        initialFilterParams = {
          label: 'custom',
          start_date: haveSelectedCustomDates
            ? new Date(selectedCustomDates?.start_date)
            : new Date(),
          end_date: haveSelectedCustomDates
            ? new Date(selectedCustomDates?.end_date)
            : new Date(),
          previous_start_date: haveSelectedCustomDates
            ? new Date(selectedCustomDates?.previous_start_date)
            : new Date(),
          previous_end_date: haveSelectedCustomDates
            ? new Date(selectedCustomDates?.previous_end_date)
            : new Date(),
        };
      } else {
        const data = getDateInterval(selectedFilter);
        initialFilterParams = {
          label: selectedFilter,
          ...data,
        };
      }
    } else {
      initialFilterParams = {
        label: 'month',
        start_date: add(yesterday, {
          days: -30,
        }),
        end_date: yesterday,
        previous_start_date: add(yesterday, {
          days: -60,
        }),
        previous_end_date: add(yesterday, {
          days: -30,
        }),
      };
    }
    dashboardContext.handleApplyFilter({
      ...filterParams,
      ...initialFilterParams,
      ...obj,
    });
  }, [authContext.currentUser]);

  const getData = async () => {
    if (
      filterParams.health_system_id ||
      filterParams.hospital_id ||
      filterParams.department_id ||
      filterParams.provider_id
    ) {
      dashboardContext.getTotalUploads();
      dashboardContext.getCQScore();
      dashboardContext.getCQEstimatedRev();
      dashboardContext.getPotentialRevenue();
      dashboardContext.getChartAccuracyBreakdown();
      dashboardContext.getAIRisk();
      if (dashboardContext.rvuOppFilter['rvu_opportunity_type']) {
        dashboardContext.getRVUOpp();
      }
      const revenue = await dashboardContext.getRevenueOpp();
      setRevenueOpp(revenue);
      const response = await dashboardContext.getChartActivity(currentUserType);
      setChartActivity(response);
    }
  };

  useEffect(() => {
    if (dropdown.length) {
      setChecked(dropdown[0]['value']);
      setRVUOppFilter({ rvu_opportunity_type: dropdown[0]['value'] });
    }
  }, [dropdown]);

  useEffect(() => {
    if (
      (filterParams.health_system_id ||
        filterParams.hospital_id ||
        filterParams.department_id ||
        filterParams.provider_id) &&
      dashboardContext.rvuOppFilter['rvu_opportunity_type']
    ) {
      dashboardContext.getRVUOpp();
    }
  }, [dashboardContext.rvuOppFilter]);

  useEffect(() => {
    const option = dashboardContext.rvuOppFilter['rvu_opportunity_type'];
    if (
      dashboardContext.rvuOpp &&
      Object.keys(dashboardContext.rvuOpp).length
    ) {
      const data = dashboardContext.rvuOpp[option];
      let practiceChartData = { label: [], data: [] };
      if (data?.practice_distribution) {
        practiceChartData.label = Object.keys(data.practice_distribution);
        practiceChartData.data = Object.keys(data.practice_distribution).map(
          d => data.practice_distribution[d]
        );
      }
      let stateChartData = { label: [], data: [] };
      if (data?.state_distribution) {
        stateChartData.label = Object.keys(data.state_distribution);
        stateChartData.data = Object.keys(data.state_distribution).map(
          d => data.state_distribution[d]
        );
      }
      let auditChartData = { label: [], data: [] };
      if (data?.revintegrity_distribution) {
        auditChartData.label = Object.keys(data.revintegrity_distribution);
        auditChartData.data = Object.keys(data.revintegrity_distribution).map(
          d => data.revintegrity_distribution[d]
        );
      }
      setPracticeChartData(practiceChartData);
      setStateChartData(stateChartData);
      setAuditChartData(auditChartData);
    }
  }, [dashboardContext.rvuOpp]);

  useEffect(() => {
    if (
      filterParams.health_system_id ||
      filterParams.hospital_id ||
      filterParams.department_id ||
      filterParams.provider_id
    ) {
      getRVUOppDropdown();
      if (Object.keys(currentUserType)?.length !== 0) {
        getData();
      }
    }
  }, [filterParams]);

  useEffect(() => {
    if (
      filterParams.health_system_id ||
      filterParams.hospital_id ||
      filterParams.department_id ||
      filterParams.provider_id
    ) {
      // eslint-disable-next-line
      dashboardContext.getProviders();
    }
  }, [dashboardContext.providerFilter, dashboardContext.filterParams]);

  const radioClick = option => {
    setInitialLoadComplete(true);
    setChecked(option);
    setRVUOppFilter(prev => ({ ...prev, rvu_opportunity_type: option }));
  };

  const onDateSubmit = dates => {
    const dateObject = {};
    if (Object.keys(dates)?.length) {
      Object.keys(dates).forEach(key => {
        dateObject[key] = dates[key] ? new Date(dates[key]) : '';
      });
    }
    dashboardContext.handleApplyFilter({
      ...filterParams,
      ...dateObject,
      label: 'custom',
    });
    localStorage.setItem('customDateFilter', JSON.stringify({ ...dateObject }));
    setShowComparison(false);
    iodfState.off();
  };

  const onDateClose = () => {
    iodfState.off();
    // if (filterParams.label === 'custom') {
    //   const data = getDateInterval('Month');
    //   dashboardContext.handleApplyFilter({
    //     ...filterParams,
    //     ...data,
    //     label: 'Month',
    //   });
    // }
  };

  const tileLoader = () => {
    return (
      <Box mx={3}>
        <Skeleton mt={5} w={'100%'} h={25} borderRadius="md" />
        <Skeleton mt={10} w={'100%'} h={200} borderRadius="md" />
        <Skeleton my={5} w={'50%'} h={25} borderRadius="md" />
      </Box>
    );
  };

  const tileFailure = (title, infoContent, message) => {
    return (
      <Box
        p={isTileModalOpen ? 0 : 5}
        bg={'white'}
        borderRadius="md"
        width={'100%'}
      >
        {renderTileHeader(title, infoContent)}
        <Box mt={10} textAlign="center" justifyContent={'center'}>
          <Text>
            {message ? message : 'Something went wrong, Please try again later'}
          </Text>
        </Box>
      </Box>
    );
  };

  const renderTileHeader = (title, infoContent, noDate) => {
    return (
      <HStack justifyContent={'space-between'}>
        {title ? (
          <Text
            fontSize={isTileModalOpen ? 'lg' : '18px'}
            color={AppColors.primary}
            fontFamily={FONT_FAMILY.ptSans}
          >
            {title}
          </Text>
        ) : null}
        {/* {!noDate ? (
          <Text
            fontSize={'xs'}
            color={AppColors.black}
            fontFamily={FONT_FAMILY.ptSans}
          >
            {filterParams.label === 'custom'
              ? null
              : filterParams.label === 'Day'
                ? dateFormat(filterParams.start_date)
                : `${dateFormat(filterParams.start_date)} - ${dateFormat(
                  filterParams.end_date
                )}`}
          </Text>
        ) : null} */}
        {/* The info tile component */}
        {infoContent ? (
          <Tooltip p={3} bg="whiteAlpha.900"
            color="black"
            borderRadius="md"
            border="1px solid #43dde0" label={
              <Box>
                {infoContent}
              </Box>
            } placement="top-end">
            <Icon as={InfoOutlineIcon} w={4} h={4} cursor="pointer" _hover={{ color: AppColors.secondary, transition: 'color 0.3s ease-in-out' }} color={AppColors.primary} />
          </Tooltip>
        ) : null}
      </HStack>
    );
  };

  const renderTileFooter = ({ diff, score }) => {
    return isTileModalOpen ? (
      <Box>
        <HStack mt={4} ml={7} justifyContent="center">
          <Text fontSize={'4xl'} ml={7} fontFamily={FONT_FAMILY.ptSans}>
            {score ?? 0}
          </Text>
          {diff >= 0 ? (
            <TriangleIcon style={{ height: 20, width: 20 }} />
          ) : (
            <TriangleRedIcon style={{ height: 20, width: 20 }} />
          )}
          <Text
            fontFamily={FONT_FAMILY.ptSans}
            fontSize={'5xl'}
            fontWeight="bold"
            mt={3}
          >
            {convertToPositiveDollar(diff)}
          </Text>
        </HStack>
      </Box>
    ) : (
      <Box>
        <HStack mt={4} ml={7}>
          {diff >= 0 ? <TriangleIcon /> : <TriangleRedIcon />}
          <Text fontSize={'xs'} fontFamily={FONT_FAMILY.ptSans}>
            {convertToPositiveDollar(diff)}
          </Text>
        </HStack>
        <Text
          fontFamily={FONT_FAMILY.ptSans}
          fontSize={'5xl'}
          fontWeight="bold"
          mt={3}
          ml={7}
        >
          {score ?? 0}
        </Text>
      </Box>
    );
  };

  const renderTotalUploads = () => {
    if (dashboardContext.isTotalUploadLoading) {
      return tileLoader();
    }
    if (dashboardContext.error['TOTAL_UPLOADS']) {
      return tileFailure(
        'Total Charts',
        (
          <>
            <p>
              <b>Total Charts</b> component provides a visual representation of the number of charts within the selected filter date range.
            </p>
          </>
        ),
        dashboardContext.error['TOTAL_UPLOADS']
      );
    }
    const {
      upload_difference,
      current_graph: graph,
      previous_graph,
      unit_type,
      current_total_uploads_count,
      filter_dates,
    } = dashboardContext.totalUpload;

    let chartData = { label: [], data: [] };
    let previousChartData = { label: [], data: [] };



    if (graph) {

      chartData = getChartData(
        filterParams.label,
        graph,
        unit_type,
        previous_graph,
        showComparison
      );
      previousChartData = getChartData(
        filterParams.label,
        previous_graph,
        unit_type,
        [],
        showComparison
      );
    }

    const startDateUTC = utcToZonedTime(
      new Date(filter_dates ? new Date(filter_dates['start_date']) : new Date())
    );

    const endDateUTC = utcToZonedTime(
      new Date(filter_dates ? new Date(filter_dates['end_date']) : new Date())
    );

    let chartDataProp = [
      {
        label: `${format(new Date(startDateUTC), 'MMM dd, yyyy')} - ${format(
          new Date(endDateUTC),
          'MMM dd, yyyy'
        )} (${unit_type? "In "+unit_type : "No Data"})`,
        data: chartData.data,
        borderColor: AppColors.primary,
        backgroundColor: AppColors.primary,
        tension: 0.4,
      },
    ];

    if (filterParams.label.toLowerCase() !== 'all' && showComparison) {
      chartDataProp = [
        {
          label: `${format(new Date(startDateUTC), 'MMM dd, yyyy')} - ${format(
            new Date(endDateUTC),
            'MMM dd, yyyy'
          )} (${unit_type? "In "+unit_type : "No Data"})`,
          data: chartData.data,
          borderColor: AppColors.primary,
          backgroundColor: AppColors.primary,
          tension: 0.4,
        },
        {
          label: `${format(
            new Date(filter_dates.previous_start_date),
            'MMM dd, yyyy'
          )} - ${format(
            new Date(filter_dates.previous_end_date),
            'MMM dd, yyyy'
          )} (${unit_type? "In "+unit_type : "No Data"})`,
          data: previousChartData?.data,
          borderColor: AppColors.secondary,
          backgroundColor: AppColors.secondary,
          tension: 0.4,
        },
      ];
    }

    return (
      <Box
        p={isTileModalOpen ? 0 : 5}
        bg={'white'}
        borderRadius="md"
        width={'100%'}
      >
        {renderTileHeader('Total Charts', (
          <>
            <p>
              <b>Total Charts</b> component provides a visual representation of the number of charts within the selected filter date range.
            </p>
          </>
        ))}
        <Box mt={4}>
          <CQLineChart
            chartData={chartDataProp}
            yLabel="# of Uploads"
            xLabel={XAXISLABEL[filterParams.label]}
            xAxis={chartData.label}
            x2Axis={chartData.comparisonLabel}
            showComparison={showComparison}
            maxTicksLimit={8}
            optionProps={{
              plugins: {
                legend: {
                  onClick: () => { },
                  // display: filterParams.label === 'custom' ? true : false,
                  display: true,
                  position: 'bottom',
                  align: 'end',
                  labels: {
                    boxWidth: 20,
                    boxHeight: 0.5,
                    color: 'black',
                    font: {
                      size: 10,
                      family: FONT_FAMILY.ptSans,
                    },
                  },
                },
                tooltip: {
                  callbacks: {
                    label: context => {
                      return context.parsed.y;
                    },
                    title: data => {
                      const context = data[0];
                      if (
                        context.datasetIndex === 1 &&
                        chartData.comparisonLabel.length
                      ) {
                        return chartData.comparisonLabel[context.parsed.x];
                      }
                      return context.label;
                    },
                  },
                },
              },
            }}
          />
        </Box>
        {renderTileFooter({
          diff: upload_difference,
          score: current_total_uploads_count,
        })}
      </Box>
    );
  };

  const renderCQScore = () => {
    if (dashboardContext.isCQScoreLoading) {
      return tileLoader();
    }
    if (dashboardContext.error['CQ_SCORE']) {
      return tileFailure('RI Score', (
        <>
          <p>
            <b>Revenue Integrity (RI) Score</b> shows changes in accuracy over time. The RI Score reflects the overall accuracy of your coding and billing processes, helping you monitor improvements or identify areas needing attention.
          </p>
        </>
      ), dashboardContext.error['CQ_SCORE']);
    }
    const {
      grade,
      cq_score_diff,
      current_graph: graph,
      current_cq_score,
      previous_graph,
      unit_type,
      filter_dates,
    } = dashboardContext.cqScore;
    let chartData = { label: [], data: [] };
    let previousChartData = { label: [], data: [] };

    let legendDate = graph && Object.keys(graph);

    if (graph) {
      chartData = getChartData(
        filterParams.label,
        graph,
        unit_type,
        previous_graph,
        showComparison
      );
      previousChartData = getChartData(
        filterParams.label,
        previous_graph,
        unit_type,
        [],
        showComparison
      );
    }

    const previous_cq_score = dashboardContext.cqScore['previous_cq_score '];

    const startDateUTC = utcToZonedTime(
      new Date(filter_dates ? new Date(filter_dates['start_date']) : new Date())
    );

    const endDateUTC = utcToZonedTime(
      new Date(filter_dates ? new Date(filter_dates['end_date']) : new Date())
    );
    // 
    let chartDataProp = [
      {
        label: `${format(
          new Date(startDateUTC),
          'MMM dd, yyyy'
        )} - ${format(
          new Date(endDateUTC),
          'MMM dd, yyyy'
        )} (${unit_type? "In "+unit_type : "No Data"})`,
        data: chartData.data,
        borderColor: AppColors.primary,
        backgroundColor: AppColors.primary,
        tension: 0.4,
      },
    ];

    if (filterParams.label.toLowerCase() !== 'all' && showComparison) {
      chartDataProp = [
        {
          label: `${format(new Date(startDateUTC), 'MMM dd, yyyy')} - ${format(
            new Date(endDateUTC),
            'MMM dd, yyyy'
          )} (${unit_type? "In "+unit_type : "No Data"})`,
          data: chartData.data,
          borderColor: AppColors.primary,
          backgroundColor: AppColors.primary,
          tension: 0.4,
        },
        {
          label: `${format(
            new Date(filter_dates.previous_start_date),
            'MMM dd, yyyy'
          )} - ${format(
            new Date(filter_dates.previous_end_date),
            'MMM dd, yyyy'
          )} (${unit_type? "In "+unit_type : "No Data"})`,
          data: previousChartData?.data,
          borderColor: AppColors.secondary,
          backgroundColor: AppColors.secondary,
          tension: 0.4,
        },
      ];
    }
    return (
      <Box p={isTileModalOpen ? 0 : 5} bg={'white'} borderRadius="md">
        {renderTileHeader('RI Score', (
          <>
            <p>
              <b>Revenue Integrity (RI) Score</b> shows changes in accuracy over time. The RI Score reflects the overall accuracy of your coding and billing processes, helping you monitor improvements or identify areas needing attention.
            </p>
          </>
        ))}
        <Box mt={4}>
          <CQLineChart
            chartData={chartDataProp}
            yLabel="Score"
            xLabel={XAXISLABEL[filterParams.label]}
            xAxis={chartData.label}
            x2Axis={chartData.comparisonLabel}
            showComparison={showComparison}
            maxTicksLimit={7}
            optionProps={{
              plugins: {
                legend: {
                  onClick: () => { },
                  display: true,
                  position: 'bottom',
                  align: 'end',
                  labels: {
                    boxWidth: 20,
                    boxHeight: 0.5,
                    color: 'black',
                    font: {
                      size: 10,
                      family: FONT_FAMILY.ptSans,
                    },
                  },
                },
                annotation: {
                  annotations: {
                    label1: {
                      type: 'label',
                      backgroundColor: 'transparent',
                      color: 'rgba(3, 49, 122, 0.4)',
                      content:
                        typeof previous_cq_score === 'number' && showComparison
                          ? [`${previous_cq_score}%`]
                          : [],
                      font: {
                        size: 25,
                        weight: 'bold',
                      },
                      xAdjust: 80,
                    },
                    label2: {
                      type: 'label',
                      backgroundColor: 'transparent',
                      color: 'rgba(34, 175, 168, 0.4)',
                      content:
                        typeof current_cq_score === 'number'
                          ? [`${current_cq_score}%`]
                          : [],
                      font: {
                        size: 25,
                        weight: 'bold',
                      },
                      xAdjust:
                        typeof previous_cq_score === 'number' && showComparison
                          ? -80
                          : 0,
                    },
                  },
                },
                tooltip: {
                  callbacks: {
                    label: context => {
                      return Math.round(context.parsed.y);
                    },
                    title: data => {
                      const context = data[0];
                      if (
                        context.datasetIndex === 1 &&
                        chartData.comparisonLabel.length
                      ) {
                        return chartData.comparisonLabel[context.parsed.x];
                      }
                      return context.label;
                    },
                  },
                },
              },
            }}
          />
        </Box>
        {renderTileFooter({ diff: cq_score_diff, score: grade })}
      </Box>
    );
  };

  const renderRVUOpportunity = () => {
    if (dashboardContext.isRVUOppLoading || dashboardContext.isFileLoading) {
      return tileLoader();
    }
    if (dashboardContext.error['RVU_OPPORTUNITY'] || dashboardContext.error['POTENTIAL_REVENUE']) {
      return tileFailure(
        'RVU OPPORTUNITY',
        (
          <>
            <p>
              <b>Relative Value Units (RVU)</b> for your practice compared to state-level bell curve distributions. RVUs, help you measure productivity and efficiency by comparing your practice's performance against broader benchmarks.
            </p>
          </>
        ),
        dashboardContext.error['RVU_OPPORTUNITY']
      );
    }
    return (
      <Box p={isTileModalOpen ? 0 : 5} bg={'white'} borderRadius="md">
        {renderTileHeader('RVU Opportunity', (
          <>
            <p>
              <b>Relative Value Units (RVU)</b> for your practice compared to state-level bell curve distributions. RVUs, help you measure productivity and efficiency by comparing your practice's performance against broader benchmarks.
            </p>
          </>
        ))}
        <Box mt={4}>
          <RVUOpportunity
            stateChartData={stateChartData}
            practiceChartData={practiceChartData}
            auditChartData={auditChartData}
            checked={checked}
            setChecked={setChecked}
            radioClick={radioClick}
            tile={isTileModalOpen}
            dropdown={dropdown}
          />
        </Box>
      </Box>
    );
  };

  const renderCQOutstanding = () => {
    if (dashboardContext.isRevenueOppLoading) {
      return tileLoader();
    }
    if (dashboardContext.error['OUTSTANDING_REVENUE']) {
      return tileFailure(
        'Outstanding Revenue Opportunity',
        (
          <>
            <p>
              <b>Outstanding Revenue Opportunity</b> shows the dollar value of potential revenue that your practice is missing out on. Identifying this outstanding revenue opportunity can help focus on areas where improvements lead to significant financial gains.
            </p>
          </>
        ),
        dashboardContext.error['OUTSTANDING_REVENUE']
      );
    }
    return (
      <Box p={5} bg={'white'} borderRadius="md" >
        {renderTileHeader('Outstanding Revenue Opportunity', (
          <>
            <p>
              <b>Outstanding Revenue Opportunity</b> shows the dollar value of potential revenue that your practice is missing out on. Identifying this outstanding revenue opportunity can help focus on areas where improvements lead to significant financial gains.
            </p>
          </>
        ))}
        <RevenueTile
          diff={revenueOpp?.difference}
          dollarValue={
            revenueOpp?.difference
              ? priceConverter(revenueOpp?.difference)
              : '$0'
          }
          description=""
          label={
            revenueOpp?.cq_outstanding_rev_opp
              ? priceConverter(revenueOpp?.cq_outstanding_rev_opp)
              : '$0'
          }
          filterParams={filterParams}
        />
      </Box>
    );
  };

  const renderRevenueBreakdown = () => {
    if (dashboardContext.isChartAccuracyBreakdownLoading || dashboardContext.isFileLoading) {
      return tileLoader();
    }
    if (dashboardContext.error['CHART_ACCURACY_BREAKDOWN'] || dashboardContext.error['POTENTIAL_REVENUE']) {
      return tileFailure(
        'Revenue Breakdown',
        (
          <>
            <p>
              <b>Revenue Breakdown</b> shows the total dollar value of charts that have been upcoded or downcoded. Understanding these adjustments helps you assess the financial impact of coding practices and identify areas for revenue optimization.
            </p>
          </>
        ),
        dashboardContext.error['CHART_ACCURACY_BREAKDOWN']
      );
    }

    let totalUpcoded = 0, totalDowncoded = 0, totalUpcodedReviewed = 0, totalDowncodedReviewed = 0;

    if (dashboardContext.chartAccuracyBreakdown && typeof dashboardContext.chartAccuracyBreakdown === 'object') {

      Object.values(dashboardContext.chartAccuracyBreakdown).forEach((item) => {
        if (item.rvu_opportunity > 0) {
          totalDowncoded += item.rvu_opportunity;
          if (item.review) {
            totalDowncodedReviewed += item.rvu_opportunity;
          }
        } else if (item.rvu_opportunity < 0) {
          totalUpcoded += item.rvu_opportunity;
          if (item.review) {
            totalUpcodedReviewed += item.rvu_opportunity;
          }
        }
      });

      totalUpcoded = totalUpcoded ? priceConverter(totalUpcoded) : '$0.00';
      totalDowncoded = totalDowncoded ? priceConverter(totalDowncoded) : '$0.00';
      totalUpcodedReviewed = totalUpcodedReviewed ? priceConverter(totalUpcodedReviewed) : '$0.00';
      totalDowncodedReviewed = totalDowncodedReviewed ? priceConverter(totalDowncodedReviewed) : '$0.00';
    }

    return (
      <Box p={5} bg={'white'} borderRadius="md">

        {showCollectedRevenue ? (
          <>
            {renderTileHeader('Revenue Reviewed', (
              <>
                <p>
                  <b>Revenue Reviewed</b> displays the total amount of revenue associated with charts that have been reviewed. This is calculated by summing the revenue of charts marked as reviewed in the Upload Breakdown tile, helping you track the financial impact of your review process.
                </p>
              </>
            ))}
            <CollectedRevenueTile
              totalUpcoded={totalUpcoded}
              totalDowncoded={totalDowncoded}
              totalUpcodedReviewed={totalUpcodedReviewed}
              totalDowncodedReviewed={totalDowncodedReviewed}
              description=""
            />
          </>
        ) : (
          <>
            {renderTileHeader('Revenue Breakdown', (
              <>
                <p>
                  <b>Revenue Breakdown</b> shows the total dollar value of charts that have been upcoded or downcoded. Understanding these adjustments helps you assess the financial impact of coding practices and identify areas for revenue optimization.
                </p>
              </>
            ))}
            <RevenueBreakdownTile
              totalUpcoded={totalUpcoded}
              totalDowncoded={totalDowncoded}
              potentialRevenue={dashboardContext.potentialRevenue}
              loadingPotentialRevenue={dashboardContext.isPotentialRevenueLoading}
              dropdown={dropdown}
              description=""
            />
          </>
        )}
        <Button borderWidth={1}
          borderColor="black"
          bg={'white'}
          ml={0}
          mb={4}
          fontFamily={FONT_FAMILY.ptSans}
          fontWeight="normal"
          _hover={{ borderColor: AppColors.primary }}
          fontSize={'sm'} onClick={toggleRevenueBreakdown} mt={4}>
          {showCollectedRevenue ? 'View Revenue Breakdown' : 'View Revenue Reviewed'}
        </Button>
      </Box>
    );
  };

  const potentialRevenueBreakdown = () => {
    if (dashboardContext.isFileLoading) {
      return tileLoader();
    }
    if (dashboardContext.error['CHART_ACCURACY_BREAKDOWN'] || dashboardContext.error['POTENTIAL_REVENUE']) {
      return tileFailure(
        'Potential Revenue Breakdown',
        (
          <>
            <p>
              <b>Potential Revenue Breakdown</b> calculates potential revenue impact by comparing your practice's coding distribution to state standards. It projects additional revenue based on a set number of charts, helping you identify opportunities for revenue recovery.
            </p>
          </>
        ),
        dashboardContext.error['CHART_ACCURACY_BREAKDOWN']
      );
    }

    return (
      <Box p={5} bg={'white'} borderRadius="md">
        {renderTileHeader('Potential Revenue Breakdown', (
          <>
            <p>
              <b>Potential Revenue Breakdown</b> calculates potential revenue impact by comparing your practice's coding distribution to state standards. It projects additional revenue based on a set number of charts, helping you identify opportunities for revenue recovery.
            </p>
          </>
        ))}
        <PotentialRevenueTile
          potentialRevenue={dashboardContext.potentialRevenue}
          loadingPotentialRevenue={dashboardContext.isPotentialRevenueLoading}
          dropdown={dropdown}
          selectedOption={checked}
          onDropdownChange={radioClick}
          initialLoadComplete={initialLoadComplete}
          description=""
        />
      </Box>
    );
  };

  const renderCQEstimatedRevenue = () => {
    if (dashboardContext.isCQEstimatedRevLoading) {
      return tileLoader();
    }

    if (dashboardContext.error['CQ_ESTIMATED_REVENUE']) {
      return tileFailure(
        '% Revenue Opportunity',
        (
          <>
            <p>
              <b>Percentage Revenue Opportunity</b> displays the percentage difference between the revenue you are currently receiving and the potential revenue you could achieve. This metric helps you identify the gap and uncover opportunities to maximize your revenue.
            </p>
          </>
        ),
        dashboardContext.error['CQ_ESTIMATED_REVENUE']
      );
    }

    const { cqEstimatedRev } = dashboardContext;
    return (
      <Box p={5} bg={'white'} borderRadius="md">
        {renderTileHeader('% Revenue Opportunity', (
          <>
            <p>
              <b>Percentage Revenue Opportunity</b> displays the percentage difference between the revenue you are currently receiving and the potential revenue you could achieve. This metric helps you identify the gap and uncover opportunities to maximize your revenue.
            </p>
          </>
        ))}
        <RevenueTile
          diff={cqEstimatedRev?.difference}
          dollarValue={cqEstimatedRev?.difference}
          description=""
          label={
            cqEstimatedRev?.cq_estimated_revenue
              ? `${cqEstimatedRev?.cq_estimated_revenue}%`
              : '0%'
          }
          filterParams={filterParams}
        />
      </Box>
    );
  };

  const renderCQAIRiskMonitor = () => {
    if (dashboardContext.isAIRiskLoading) {
      return tileLoader();
    }

    if (dashboardContext.error['AI_RISK']) {
      return tileFailure(
        'Revenue Cycle Temperature Gauge',
        (
          <>
            <p>
              <b>Revenue Cycle Temperature Gauge</b> represents the overall health of your revenue cycle. High temperatures indicate potential compliance risks, while low temperatures suggest possible revenue leaks. You can set specific parameters in the 'Practice Info' tab to customize this gauge.
            </p>
          </>
        ),
        dashboardContext.error['AI_RISK']
      );
    }

    return (
      <Box p={isTileModalOpen ? 0 : 5} bg={'white'} borderRadius="md" position="relative">
        {renderTileHeader('Revenue Cycle Temperature Gauge', (
          <>
            <p>
              <b>Revenue Cycle Temperature Gauge</b> represents the overall health of your revenue cycle. High temperatures indicate potential compliance risks, while low temperatures suggest possible revenue leaks. You can set specific parameters in the 'Practice Info' tab to customize this gauge.
            </p>
          </>
        ))}
        <Box mt={4}>
          <AIRiskMonitor
            data={dashboardContext.aiRisk}
            tile={isTileModalOpen}
          />
        </Box>
      </Box>
    );
  };

  const renderChartActivity = () => {
    const LABEL = {
      modifier_percentage: 'Modifier',
      rvu_percentage: 'RVU Opportunities',
      upcoded_percentage: 'Upcoded',
      downcoded_percentage: 'Downcoded',
    };

    return (
      <Box p={5} bg={'white'} borderRadius="md">
        {renderTileHeader('Uploaded Chart Activity', (
          <>
            <p>
              <b>Uploaded Chart Activity</b> breaks down chart accuracy into more concise categories to help you find actionable insights. Use this information to identify specific issues and enhance the accuracy of your coding and billing processes.
            </p>
          </>
        ))}
        <Grid
          templateColumns={
            !isUserProvider ? 'repeat(4, 1fr)' : 'repeat(4, 1fr)'
          }
          gap={2}
          justifyContent="center"
          mt={5}
        >
          {chartActivity && Object.keys(chartActivity)?.length
            ? Object.keys(LABEL).map(chart => {
              return (
                <GridItem>
                  <Box display="flex" justifyContent={'center'}>
                    <CircularProgress
                      value={chartActivity[chart] ? chartActivity[chart] : 0}
                      size="150px"
                      thickness="3px"
                      color={AppColors.primary}
                    >
                      <CircularProgressLabel>
                        <Heading
                          variant="h1"
                          fontFamily={FONT_FAMILY.ptSans}
                          fontWeight="md"
                        >
                          {chartActivity[chart] ? chartActivity[chart] : 0}%
                        </Heading>
                      </CircularProgressLabel>
                    </CircularProgress>
                  </Box>
                  <Text
                    textAlign={'center'}
                    fontFamily={FONT_FAMILY.ptSans}
                    fontSize="md"
                  >
                    {LABEL[chart]}
                  </Text>
                </GridItem>
              );
            })
            : null}
        </Grid>
      </Box>
    );
  };

  const renderChartActivityTile = () => {
    if (dashboardContext.isChartActivityLoading) {
      return tileLoader();
    } else {
      return renderChartActivity();
    }
  };

  const renderProviderTable = () => {
    return (
      <Box p={5} bg={'white'} borderRadius="md">
        {renderTileHeader('Providers', (
          <>
            <p>
              <b>Providers Tile</b> lists all providers in your organization, allowing you to rank or sort them based on accuracy or changes in accuracy from the previous time period. This helps you monitor individual provider performance and identify areas for improvement.
            </p>
          </>
        ))}
        {/* <span style={{ color: 'black' }}>
              (
              {filterParams.label === 'Day'
                ? dateFormat(filterParams.start_date)
                : `${dateFormat(filterParams.start_date)} - ${dateFormat(
                  filterParams.end_date
                )}`}
              )
            </span> */}
        <ProvidersTable />
      </Box>
    );
  };

  const renderProviderChartAnalytics = () => {
    return (
      <Box p={5} mt={5} bg={'white'} borderRadius="md">
        {renderTileHeader('Provider Chart Analytics', (
          <>
            <p>
              <b>Provider Chart Analytics</b> provides in-depth view of individual provider statistics, including detailed analytics on chart accuracy and performance. Providers can also book education sessions directly from this tile to improve their coding skills and compliance.
            </p>
          </>
        ))}
        <HStack
          width={'100%'}
          justifyContent={'space-around'}
          my={4}
          w={'100%'}
        >
          <Text fontFamily={FONT_FAMILY.ptSans} color={AppColors.black}>
            My Analytics
          </Text>
          <PrimaryButton
            bg={AppColors.secondary}
            fontSize={'14px'}
            fontWeight="normal"
            size="md"
            borderRadius="3xl"
            px="10"
            onClick={() => {
              const route = replaceRoute(
                ROUTES.DEPARTMENT_PHYSICIAN_DASHBOARD,
                { physicianId: currentUser?.provider_id?.['providers__id'] }
              );
              navigate(route);
            }}
          >
            View
          </PrimaryButton>
        </HStack>
      </Box>
    );
  };

  const renderUploadTable = () => {
    return (
      <Box mt={5} p={5} bg={'white'} borderRadius="md" shadow={'md'}>
        {renderTileHeader('Upload Breakdown ', (
          <>
            <p>
              <b>Upload Breakdown</b> details each batch of charts uploaded, sortable by each column header. It can also function as a work queue by using the checkboxes in the far-right column, allowing you to track and manage chart uploads effectively.
            </p>
          </>
        ))}
        <UploadTable filterParams={filterParams} />
      </Box>
    );
  };

  const renderHospital = () => {
    switch (currentUser?.user_type) {
      case 'HEALTH SYSTEM':
        return (
          <Box mt={5}>
            <HospitalsTable />
          </Box>
        );
      case 'HOSPITAL':
        return <DepartmentTable />;
      case 'DEPARTMENT':
        return <PhysicianTable />;
      case 'PHYSICIANS GROUP':
        return (
          <Box mt={5}>
            <HospitalsTable />
          </Box>
        );
      default:
        return null;
    }
  };

  const TILE_MODAL_BODY = {
    TOTAL_UPLOAD: renderTotalUploads,
    CQ_SCORE: renderCQScore,
    RVU_OPPORTUNITY: renderRVUOpportunity,
    AI_RISK: renderCQAIRiskMonitor,
  };
  return (
    <CQContainer>
      <Box>
        <Box width={'100%'}>
          <HStack justifyContent={'space-between'} alignItems="center" mb={3}>
            <Heading
              as="h3" fontSize="24px"
              mb={-7}
            >{`${name} Analytics`}</Heading>
            {/* {renderFilters()} */}
            <CustomDateFilter
              filterParams={filterParams}
              openDateFilter={() => iodfState.on()}
              isOpenDateFilter={isOpenDateFilter}
              onDateClose={onDateClose}
              onDateSubmit={onDateSubmit}
              isLoading={isLoading}
              objectWithDate={dashboardContext.cqScore}
              onSelectClick={filter => {
                if (filter.value !== 'custom') {
                  const data = getDateInterval(filter.value);
                  dashboardContext.handleApplyFilter({
                    ...filterParams,
                    label: filter.value,
                    ...data,
                  });
                  localStorage.setItem(
                    'selectedFilter',
                    JSON.stringify(filter.value.toLowerCase())
                  );
                  window.localStorage.removeItem('customDateFilter');
                  iodfState.off();
                  setShowComparison(false);
                }
              }}
            />
          </HStack>
          <HStack justifyContent={'end'}>
            <Switch
              icon={<CustomIcon />}
              mb={7}
              mr={1}
              id="show-comparison"
              isChecked={showComparison}
              onChange={async e => {
                setShowComparison(e.target.checked);
              }}
              border={'black'}
              colorScheme="secondary"
              isDisabled={filterParams.label === 'all'}
            >
              Show Comparison Data
            </Switch>
          </HStack>
          <Grid
            templateColumns={{
              sm: 'repeat(1, 1fr)',
              md: 'repeat(2, 1fr)',
              lg: 'repeat(2, 1fr)',
              '2lg': 'repeat(2, 1fr)',
              xl: 'repeat(3, 1fr)',
              '2xl': 'repeat(3, 1fr)',
            }}
            gap={4}
            rowGap={4}
          >
            <GridItem
              borderRadius="md"
              shadow={'md'}
              bg={'white'}
              width={'99%'}
              cursor={'pointer'}
              onClick={() => {
                setCurrentTile('AI_RISK');
                itmState.on();
              }}
            >
              {renderCQAIRiskMonitor()}
            </GridItem>
            <GridItem
              borderRadius="md"
              shadow={'md'}
              bg={'white'}
              width={'99%'}
              cursor={'pointer'}
              onClick={() => {
                setCurrentTile('CQ_SCORE');
                itmState.on();
              }}
            >
              {renderCQScore()}
            </GridItem>
            <GridItem
              borderRadius="md"
              shadow={'md'}
              bg={'white'}
              width={'99%'}
              cursor={'pointer'}
              onClick={e => {
                setCurrentTile('RVU_OPPORTUNITY');
                itmState.on();
              }}
            >
              {renderRVUOpportunity()}
            </GridItem>
            <GridItem borderRadius="md" bg={'white'} width={'99%'} shadow={'md'}>
              {showOutstanding ? (
                <>
                  {renderCQOutstanding()}
                  <Button
                    borderWidth={1}
                    borderColor="black"
                    bg={'white'}
                    ml={5}
                    mb={4}
                    fontFamily={FONT_FAMILY.ptSans}
                    fontWeight="normal"
                    _hover={{ borderColor: AppColors.primary }}
                    fontSize={'sm'}
                    onClick={toggleComponent}
                  >
                    View % Revenue Opportunity
                  </Button>
                </>
              ) : (
                <>
                  {renderCQEstimatedRevenue()}
                  <Button
                    borderWidth={1}
                    borderColor="black"
                    bg={'white'}
                    ml={5}
                    mb={4}
                    fontFamily={FONT_FAMILY.ptSans}
                    fontWeight="normal"
                    _hover={{ borderColor: AppColors.primary }}
                    fontSize={'sm'}
                    onClick={toggleComponent}
                  >
                    View Outstanding Revenue Opportunity
                  </Button>
                </>
              )}
            </GridItem>
            <GridItem borderRadius="md" bg={'white'} width={'99%'} shadow={'md'}>
              {renderRevenueBreakdown()}
            </GridItem>
            <GridItem
              borderRadius="md"
              width={'99%'}
              bg={'white'}
              shadow={'md'}

            >
              {/* {renderChartActivityTile()} */}
              {potentialRevenueBreakdown()}
            </GridItem>
          </Grid>
          {!isUserProvider ? (
            <Grid
              templateColumns={{
                sm: 'repeat(1, 1fr)',
                md: 'repeat(1, 1fr)',
                lg: 'repeat(1, 1fr)',
              }}
              gap={2}
              mt={5}
            >
              <GridItem borderRadius="md" bg={'white'} width={'99%'} shadow={'md'}>
                {renderChartActivity()}
              </GridItem>
            </Grid>
          ) : (
            <Grid
              templateColumns={{
                sm: 'repeat(1, 1fr)',
                md: 'repeat(1, 1fr)',
                lg: 'repeat(1, 1fr)',
              }}
              mt={5}
              gap={2}
            >
              <GridItem borderRadius="md" bg={'white'} width={'99%'} shadow={'md'}>
                {renderChartActivityTile()}
              </GridItem>
            </Grid>
          )}
          {!isUserProvider ? (
            <Grid
              templateColumns={{
                sm: 'repeat(1, 1fr)',
                md: 'repeat(1, 1fr)',
                lg: 'repeat(2, 1fr)',
              }}
              gap={2}
              mt={5}
            >
              <GridItem borderRadius="md" bg={'white'} width={'99%'} shadow={'md'} cursor={'pointer'} onClick={e => {
                setCurrentTile('TOTAL_UPLOAD');
                itmState.on();
              }}>
                {/* {renderChartActivity()} */}
                {renderTotalUploads()}
              </GridItem>
              <GridItem
                borderRadius="md"
                bg={'white'}
                width={'99%'}
                shadow={'md'}
              >
                {renderProviderTable()}
              </GridItem>
            </Grid>
          ) : (
            <Grid
              templateColumns={{
                sm: 'repeat(1, 1fr)',
                md: 'repeat(2, 1fr)',
                lg: 'repeat(2, 1fr)',
              }}
              gap={2}
              mt={5}
            >
              <GridItem borderRadius="md" bg={'white'} width={'99%'} shadow={'md'} cursor={'pointer'} onClick={e => {
                setCurrentTile('TOTAL_UPLOAD');
                itmState.on();
              }}>
                {/* {renderChartActivity()} */}
                {renderTotalUploads()}
              </GridItem>
              <GridItem
                borderRadius="md"
                bg={'white'}
                width={'99%'}
                shadow={'md'}
              >
                {renderProviderChartAnalytics()}
              </GridItem>
            </Grid>
          )}
          {renderUploadTable()}
          {renderHospital()}
        </Box>
        <TilePopup
          renderBody={currentTile ? TILE_MODAL_BODY[currentTile] : () => null}
          isOpen={isTileModalOpen}
          onClose={() => {
            itmState.off();
          }}
        />
      </Box>
    </CQContainer>
  );
};

export default withProvider(DashboardProvider, DashboardContainer);
